export  const clickProductAlgoliaTrendingItems = (sku: string) => {
    let dataLayer = window.dataLayer || [];

    dataLayer.push({
        'event': 'GAevent',
        'eventName': 'algolia-event',
        'cta-name': 'algolia-trending-items',
        'product-sku': `${sku}`
    });
}
