/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import * as React from "react";

export interface IAppConfig {
    baseUrl: string,
    viewFileUrl: string,
    currency: string,
    locale: string,
    storeCode: string,
    storeId: string,
    algoliaApplicationID: string,
    algoliaSearchOnlyAPIKey: string,
    algoliaIndexName: string,
    title: string,
    cachePath: string,
    cacheApiToken: string
}

interface IContextProps {
    config: IAppConfig
}

export const AppConfigContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    config: IAppConfig;
    children: React.ReactNode;
}

const AppConfigProvider = (props: IContextProviderProps) => {

    const currentConfig = props.config;

    return (
        <AppConfigContext.Provider
            value={{
                config: currentConfig
            }}
        >
            {props.children}
        </AppConfigContext.Provider>
    );
};

export default AppConfigProvider;
