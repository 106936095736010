/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import * as React from "react";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppConfigContext} from "./AppConfigProvider";
import recommend from "@algolia/recommend";
import {ITrendingItemsProduct} from "../../interface/componente-interface";

interface IContextProps {
    products: ITrendingItemsProduct[];
}

export interface IPrice {
    default: number;
    default_formated: string;
    special_from_date: string;
    special_to_date: string;
}

interface IAlgoliaProductData {
    objectID: string;
    image_url: string;
    name: string;
    price: Record<string, IPrice>;
    sku: string;
    url: string;
}

interface ICacheData {
    results: { hits: IAlgoliaProductData[] }[]
}

export const ProductsContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    children: React.ReactNode;
}

const ProductsProvider = (props: IContextProviderProps) => {
    const { config } = useContext(AppConfigContext);
    const { baseUrl, algoliaSearchOnlyAPIKey, algoliaIndexName, algoliaApplicationID, cachePath, cacheApiToken} = config;
    const recommendClient = useMemo(() => recommend(algoliaApplicationID, algoliaSearchOnlyAPIKey), [
        algoliaSearchOnlyAPIKey, algoliaApplicationID
    ]);
    const [products, setProducts] = useState<any>([]);

    const fetchFromCache = async (): Promise<IAlgoliaProductData[]> => {
        try {
            const response = await fetch(`${baseUrl}${cachePath}/load/${algoliaIndexName}`);
            if (!response.ok) throw new Error('Cache miss');
            const data = await response.json();
            return JSON.parse(data)[0].hits;
        } catch (error) {
            return [];
        }
    };

    const saveToCache = async (products: ICacheData): Promise<void> => {
        try {
            await fetch(`${baseUrl}${cachePath}/save/${algoliaIndexName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cacheApiToken}`,
                },
                body: JSON.stringify(products),
            });
        } catch (error) {
            console.error('Failed to save to cache:', error);
        }
    };

    const fetchFromAlgolia = async (): Promise<any[]> => {
        const productsFromAlgolia = await recommendClient.getTrendingItems([{indexName: algoliaIndexName}])
        .then(({ results }) => {
            return results[0].hits
        })
        .catch(err => {
            console.error(err);
        })
        if (productsFromAlgolia !== undefined && productsFromAlgolia.length) {
            return productsFromAlgolia;
        } else {
            return [];
        }
    };

    const fetchProducts = async (): Promise<IAlgoliaProductData[]> => {
        const cachedProducts = await fetchFromCache();
        if (cachedProducts && cachedProducts.length > 0) {
            console.log("Products from CACHE")
            return cachedProducts;
        } else {
            console.log("Products from ALGOLIA")
            const products: any = await fetchFromAlgolia();
            await saveToCache({ "results": [{"hits": products}]});
            return products;
        }
    };

    useEffect(() => {
        fetchProducts().then(data => setProducts(() => {
            return data.reduce(
                (acc: IAlgoliaProductData[], item: IAlgoliaProductData) => {
                    acc.push({
                        objectID: item.objectID,
                        image_url: item.image_url,
                        name: item.name,
                        price: item.price,
                        sku: item.sku,
                        url: item.url
                    })
                    return acc;
                },
                []
            )
        }));
        // eslint-disable-next-line
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                products
            }}
        >
            {props.children}
        </ProductsContext.Provider>
    );
};

export default ProductsProvider;
