/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import React, {useContext, useEffect} from "react";
import {IAppComponentProps} from "../../interface/componente-interface";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination,Autoplay} from "swiper";
import {AppConfigContext} from "../context/AppConfigProvider";
import showAlgoliaTrendingItemsBlock from "../libs/show-algolia-trending-items-block";
import {clickProductAlgoliaTrendingItems} from "../libs/gtm-tracking-algolia.js";

interface IComponentProps extends IAppComponentProps {
    trendingItems: any[];
}

const ProductCarousel: React.FC<IComponentProps> = (
    {
        className = "",
        style = {},
        trendingItems
    }
) => {
    const { config } = useContext(AppConfigContext);

    const formatPrice = (price: any) => {
        const { currency} = config;
        const p = price[currency];
        return p.default_formated;
    }

    useEffect(() => {
        if(trendingItems.length > 0) {
            showAlgoliaTrendingItemsBlock();
        }
    }, [trendingItems])

    const clickHandle = (item: any) => {
        // item.sku can be array or string
        const sku = Array.isArray(item.sku) ? item.sku[0] : item.sku;

        clickProductAlgoliaTrendingItems(sku);

        window.location.href = item.url;
    }

    return (
        <div className={"nn-trending-items"}>
            {trendingItems && <div className="swiperContainer">
                    <div className="swiperWrap">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        slidesOffsetAfter={0}
                        slidesOffsetBefore={0}
                        autoplay={{
                            delay: 5000,
                        }}
                        speed={400}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2
                            },
                            // when window width is >= 1024
                            768: {
                                slidesPerView: 4
                            },
                            // when window width is >= 1024
                            1024: {
                                slidesPerView: 5
                            },
                        }}
                        modules={[Navigation,Pagination,Autoplay]}
                        className="nnSwiper"
                    >
                        {
                            trendingItems && trendingItems.map((item: any, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <a className="productLink"
                                           onClick={() => clickHandle(item)}
                                        >
                                            <img className="productImage"
                                                 src={item.image_url}
                                                 alt={item.name}
                                            />
                                            <div className="productDetails">
                                                <div className="productName">{item.name}</div>
                                                <div className="productPrice">{formatPrice(item.price)}</div>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>
            </div>}
        </div>
    );
};

export default ProductCarousel;
