import React from 'react';

import AppConfigProvider, {IAppConfig} from "./context/AppConfigProvider";
import ProductsProvider from "./context/ProductsProvider";
import LocaleProvider from "./components/Locale/LocaleProvider";
import ProductCarouselContainer from "./components/ProductCarouselContainer";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import './App.scss';

interface IAppProps {
    config: IAppConfig
}

const App: React.FC<IAppProps> = ({ config}) => {


   return (
       <AppConfigProvider config={config} >
           <LocaleProvider>
               <ProductsProvider>
                   <ProductCarouselContainer/>
               </ProductsProvider>
           </LocaleProvider>
       </AppConfigProvider>
   );
}

export default App;
