/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import * as React from "react";
import {useCallback, useContext} from "react";
import t from "../../libs/transalte";
import { AppConfigContext } from "../../context/AppConfigProvider";

interface IContextProps {
    t: (s: string, args?: any) => string;
    locale: string;
}

export const LocaleContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    children: React.ReactNode;
}

const LocaleProvider = (props: IContextProviderProps) => {
    const { config } = useContext(AppConfigContext);
    const { locale } = config;

    /**
     * Localized version of "t"
     * @param s
     * @param args
     */
    const tl = useCallback(
        (s: string, args?: any) => {
            return t(locale, s, args);
        },
        [locale],
    );

    return (
        <LocaleContext.Provider
            value={{
                t: tl,
                locale,
            }}
        >
            {props.children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;

