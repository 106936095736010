/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import React, {useContext} from "react";
import ProductCarousel from "./ProductCarousel";
import {ProductsContext} from "../context/ProductsProvider";


const ProductCarouselContainer = () => {
    const { products } = useContext(ProductsContext);

    return <ProductCarousel trendingItems={products} />;
};

export default ProductCarouselContainer;
