/*
 * Copyright © MageSpecialist - Skeeller srl. All rights reserved.
 * See COPYING.txt for license details.
 */
import lang from "../i18n";

/**
 * Map a string to its translation
 * @param locale
 * @param s
 */
const mapString = (locale: string, s: string): string => {
    // @ts-ignore
    if (!lang.has(locale) || !lang.get(locale).has(s)) {
        return s;
    }

    // @ts-ignore
    return lang.get(locale).get(s);
};

/**
 * Translate a string with locale code selection
 * @param locale
 * @param s
 * @param args
 */
export const t = (locale: string, s: string, args?: any): string => {
    let out = mapString(locale, s);

    if (args) {
        for (const k in args) {
            if (!isNaN(Number(k))) {
                out = out.replace("%" + (Number(k) + 1), args[k]);
            } else {
                out = out.replace("%" + k, args[k]);
            }
        }
    }

    return out;
};

export default t;
